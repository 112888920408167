import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Quote from "components/case-study-quote/"

// Images

const StyledQuote = styled(Quote)`
  margin-top: 40px;
`

const Challange = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/osde/jorge-marino.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const { t } = useTranslation()
  return (
    <Section>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The challenge</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Will OSDE’s doctors adopt this digital platform as part of their
              everyday work?
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              OSDE has worked for over 45 years to bring a unique healthcare
              service to over 2 million customers. Recently the company has been
              working hard to create a new ecosystem of mobile apps to bring a
              more efficient, agile, and practical service to their customers
              and doctors in their network.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              In Argentina, the medical sector hadn’t seen many advances in the
              digital world, although there was much interest in making
              doctor-patient interactions more agile, organized, and monetized.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              The OSDE team had ideas for multiple tools that they wanted to
              translate into digital products. For this particular project, they
              wanted to create a product that could be used daily with the goal
              of improving patient-doctor relationships and giving doctors more
              efficient options for managing patients.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              This was a perfect challenge for the Design Sprint methodology.
              We've been working together with Litebox, our development
              partners, and OSDE since 2019. We joined forces with them and
              design partner Purple Bunny to create an all-star Design Sprint
              team.
            </Trans>
          </p>

          <StyledQuote
            quote={t(
              "“We were basing our hypotheses on our own perceptions and we didn’t have enough outside viewpoints.”"
            )}
            author="Jorge Marino"
            position={t("Product Manager and Decider, OSDE")}
            image={data.file.childImageSharp.fixed}
            backgroundColor={colors.indiblue}
            color={colors.white}
          />
        </div>
      </div>
    </Section>
  )
}

export default Challange