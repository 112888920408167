import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Section from "components/case-study-section/"
import UserTest from "components/case-study-user-test/"
import Quote from "components/case-study-quote/"

const UserTestWrapper = styled.div`
  margin: 40px 0;
  padding: 16px 0;

  ${breakpoint.medium`
    width: 704px;
    position: relative;
    left: -32px;
    padding: 32px 32px 16px 32px;
    box-shadow: 4px 4px 25px rgba(8, 44, 78, 0.15);
  `}
`

const StyledQuote = styled(Quote)`
  margin-top: 40px;
`

const UserTesting = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/osde/jorge-marino.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <Section>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title title--small">
            <Trans>Day 4 - User testing</Trans>
          </p>
          <p>
            <Trans>
              The moment of truth arrived on the fourth day — user testing. We
              were ready to test our simple, clear and attractive design.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>
              Recruiting users wasn’t easy at all — doctors are busy, especially
              the week before Christmas. We managed to find 5 doctors in
              different disciplines.
            </Trans>
          </p>

          <br />

          <p>
            <Trans>We used the</Trans>
            <a
              href="https://www.smashingmagazine.com/2013/04/rainbow-spreadsheet-collaborative-ux-research-tool/"
              className="strikethrough"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Rainbow Spreadsheet</Trans>
            </a>
            <Trans>
              , a user experience research method, to take notes and process
              feedback during the user tests and find patterns in relation to
              our proposed solutions.
            </Trans>
          </p>

          <UserTestWrapper>
            <UserTest
              text={t(
                "Think that video appointments could be useful in certain cases to optimize time."
              )}
              result="6/6"
              red
              orange
              yellow
              green
              blue
              purple
            />
            <UserTest
              text={t(
                "Frustrated with the current use of WhatsApp to contact doctors, which takes up time and isn't billable."
              )}
              result="6/6"
              red
              orange
              yellow
              green
              blue
              purple
            />
            <UserTest
              text={t(
                "Had a positive view of the option for online prescriptions."
              )}
              result="5/6"
              red
              orange
              green
              blue
              purple
            />
            <UserTest
              text={t(
                "Think that the medical history feature is a fantastic tool and would use it to access previous medical exams."
              )}
              result="4/6"
              red
              yellow
              green
              blue
            />
            <UserTest
              text={t(
                "Had a positive view of the dashboard section with statistics about the doctor’s office."
              )}
              result="4/6"
              yellow
              green
              blue
              purple
            />
          </UserTestWrapper>

          <p>
            <Trans>
              The user test results showed the exact opposite of our pre-Sprint
              assumptions.{" "}
            </Trans>
            <b>
              <Trans>
                That’s why we love this methodology — it demolishes any
                preconceived notions.
              </Trans>
            </b>

            <Trans>
              We couldn’t wait to get it all into a report to share with OSDE.
            </Trans>
          </p>

          <StyledQuote
            quote={t(
              "“We assumed that doctors don’t want to offer video appointments, and the tests demonstrated that they want to start trying them.”"
            )}
            author="Jorge Marino"
            position={t("Product Manager and Decider, OSDE")}
            image={data.file.childImageSharp.fixed}
            backgroundColor={colors.indiblue}
            color={colors.white}
          />
        </div>
      </div>
    </Section>
  )
}

export default UserTesting