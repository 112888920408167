 import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Section from "components/case-study-section/"
import Quote from "components/case-study-quote/"

const ImageWrapper = styled.div`
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  ${breakpoint.small`
    padding: 0 40px;
  `}

  ${breakpoint.medium`
    padding: 0;
    margin-top: 80px;
  `}

  .image {
    width: 100%;
    height: 400px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    ${breakpoint.medium`
      height: 456px;
      margin: 0;

      &.first {
        width: calc(42% - 12px);
      }

      &.second {
        width: calc(32% - 12px);
      }

      &.third {
        width: calc(26% - 12px);
      }
    `}
  }
`

const StyledQuote = styled(Quote)`
  margin-top: 60px;

  ${breakpoint.medium`
    margin-top: 120px;
  `}

  .quote {
    ${breakpoint.medium`
      padding: 32px;
    `}
  }
`

const Results = () => {
  const data = useStaticQuery(graphql`
    query {
      imageFernando: file(
        relativePath: { eq: "case-studies/osde/fernando-dambrosio.png" }
      ) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      imageGridOne: file(
        relativePath: { eq: "case-studies/osde/the-results-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1048, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageGridTwo: file(
        relativePath: { eq: "case-studies/osde/the-results-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 832, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageGridThree: file(
        relativePath: { eq: "case-studies/osde/the-results-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 616, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <Section>
      <div className="container">
        <div className="section__title">
          <p className="sticky">
            <Trans>The results</Trans>
          </p>
        </div>
        <div className="section__content">
          <h3>
            <Trans>A clear vision of how to implement telemedicine</Trans>
          </h3>
          <br />
          <p>
            <Trans>
              We translated all of the results of the Sprint and user testing
              into a report so that the OSDE team could follow our
              recommendations and next steps. This process helped them clarify
              their vision and identify their doctor's real needs. They have a
              long way to go, but knowing they're going in the right direction -
              in a single week of high-fidelity prototyping - is the most
              efficient and productive way to kick off this product.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              Right now, video appointments and digital prescriptions are
              critical for enforcing social distancing and not saturating
              medical centers, urgent-care facilities and hospitals. We love
              that we were able to participate in a project like this that has
              immediate, visible benefits for the community, and we hope that
              OSDE is able implement their solutions soon.
            </Trans>
          </p>
        </div>
      </div>

      <ImageWrapper>
        <BackgroundImage
          className="image first"
          fluid={data.imageGridOne.childImageSharp.fluid}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />

        <BackgroundImage
          className="image second"
          fluid={data.imageGridTwo.childImageSharp.fluid}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />

        <BackgroundImage
          className="image third"
          fluid={data.imageGridThree.childImageSharp.fluid}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      </ImageWrapper>

      <div className="container">
        <StyledQuote
          quote={t(
            "“We saw incredibly valuable results in a single week. Now we have tons of information to start implementing, and we already want to do another Design Sprint.”"
          )}
          author="Fernando D'Ambrosio"
          position={t("Product Owner, OSDE")}
          image={data.imageFernando.childImageSharp.fixed}
          backgroundColor={colors.indiblue}
          color={colors.white}
        />
      </div>
    </Section>
  )
}

export default Results