import React from "react"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"

// Icons
import Arm from "assets/icons/case-studies/osde/arm.inline.svg"

const StyledBanner = styled.div`
  width: 100%;
  position: relative;
  padding: 80px 24px;
  background-color: ${colors.indiblue};
  color: ${colors.white};
  overflow: hidden;

  ${breakpoint.medium`
    padding: 80px 96px;
  `}

  h3 {
    font-weight: 500;
  }

  .banner__icon {
    position: absolute;
    top: -80px;
    left: -24px;
    transform: scale(0.65) rotate(-90deg);

    ${breakpoint.medium`
      top: 0;
      right: -16px;
      left: auto;
      transform: scale(1) rotate(0deg);
    `}

    * {
      stroke: ${colors.white};
    }
  }
`

const Banner = () => (
  <Section>
    <div className="container">
      <StyledBanner>
        <h3>
          <Trans>
            OSDE’s Telemedicine solution becomes even more urgent with COVID-19
          </Trans>
        </h3>
        <br />
        <p className="paragraph--small">
          <Trans>
            Right now, in the midst of the global pandemic, it seems obvious
            that telemedicine is the way to go, and healthcare providers have
            never been so willing to adopt new technologies. But in November
            2019, months before the pandemic hit, OSDE was already looking ahead
            to get their doctors on board with a new telemedicine app. In a
            single week, we used the Design Sprint process to help them create a
            mass of possible solutions, rapidly prototype high-fidelity designs
            of the app, and validate the solution with real users — doctors in
            their network.
          </Trans>
        </p>

        <div className="banner__icon">
          <Arm />
        </div>
      </StyledBanner>
    </div>
  </Section>
)

export default Banner
