import React from "react"

// Libraries
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"

// Layout
import Layout from "layouts/layout-primary"

// Components
import ContextConsumer from "components/context/"
import Seo from "components/seo/"
import { CaseStudyTopic } from "components/case-study-section/"

// Sections
import Hero from "sections/case-studies/osde/hero"
import Banner from "sections/case-studies/osde/banner"
import TheChallenge from "sections/case-studies/osde/the-challenge"
import TheSolution from "sections/case-studies/osde/the-solution"
import WeekOne from "sections/case-studies/osde/week-one"
import Prototyping from "sections/case-studies/osde/prototyping"
import UserTesting from "sections/case-studies/osde/user-testing"
import TheResults from "sections/case-studies/osde/the-results"
import Cta from "components/case-study-call-to-action/"
import NextProject from "components/case-study-next-project/"
import NextProjectHero from "sections/case-studies/samsung/hero"

const ACGEN = props => {
  const {
    openGraphImage: { file: publicURL },
  } = props.data

  const { t } = useTranslation()
  return (
    <Layout menuColor={colors.supernova}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Kalam&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (!data.darkMenu) {
            set({ darkMenu: true })
          }
          if (data.showFooter) {
            set({ showFooter: false })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("OSDE Case Study - Indicius Design Agency")}
        description={t(
          "We design websites, apps, and brand identities for amazing clients. Read up on how we validated telemedicine with a top health insurance provider"
        )}
        image={publicURL}
      />
      <Hero />
      <CaseStudyTopic>
        <Banner />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheChallenge />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheSolution />
        <WeekOne />
        <Prototyping />
        <UserTesting />
      </CaseStudyTopic>
      <CaseStudyTopic>
        <TheResults />
      </CaseStudyTopic>
      <Cta
        title={t(
          "Need to validate a risky idea and get results in just one week?"
        )}
      />
      <NextProject url="/work/samsung" nextProjectHero={<NextProjectHero />} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    openGraphImage: file(
      relativePath: { eq: "case-studies/osde/featured-image.png" }
    ) {
      publicURL
    }
  }
`

export default ACGEN
