import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const ImageWrapper = styled.div`
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  ${breakpoint.small`
    padding: 0 40px;
  `}

  ${breakpoint.medium`
    padding: 0;
    margin-top: 80px;
  `}

  .image {
    width: 100%;
    height: 224px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    ${breakpoint.medium`
      height: 456px;
      margin: 0;

      &:first-child {
        width: 62%;
      }

      &:last-child {
        width: calc(38% - 32px);
      }
    `}
  }
`

const Solution = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/osde/week-zero-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1632, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/osde/week-zero-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 864, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Section>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Run a Design Sprint to validate OSDE’s new value proposition
            </Trans>
          </h3>
          <br />
          <p className="subtitle">
            <Trans>Week 0</Trans>
          </p>
          <p className="title">
            <Trans>Research & Preparation</Trans>
          </p>
          <p>
            <Trans>Our Sprint team included:</Trans>
          </p>
          <ul className="bullets">
            <li>
              <Trans>Product Manager</Trans>
            </li>
            <li>
              <Trans>Product Owner</Trans>
            </li>
            <li>
              <Trans>Chief of Medical Emergencies</Trans>
            </li>
            <li>
              <Trans>Chief of Contracts</Trans>
            </li>
            <li>
              <Trans>Scrum Master</Trans>
            </li>
            <li>
              <Trans>Business Analyst</Trans>
            </li>
          </ul>
          <br />
          <p>
            <Trans>
              Each person’s input across OSDE’s diverse team allowed us to get a
              360 degree view before starting the Sprint.{" "}
            </Trans>
          </p>
        </div>
      </div>

      <ImageWrapper>
        <BackgroundImage
          className="image"
          fluid={data.imageOne.childImageSharp.fluid}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />

        <BackgroundImage
          className="image"
          fluid={data.imageTwo.childImageSharp.fluid}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      </ImageWrapper>
    </Section>
  )
}

export default Solution