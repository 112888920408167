import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Section from "components/case-study-section/"

// Icons
import PostIt from "assets/icons/case-studies/osde/prototyping-post-it.inline.svg"

const StyledSection = styled(Section)`
  background-color: ${colors.cream};

  .section__title {
    text-align: center;

    ${breakpoint.medium`
      text-align: left;
    `}
  }
`

const Prototyping = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/osde/full-website-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title title--small">
            <Trans>Day 3 - Prototyping</Trans>
          </p>
          <p>
            <Trans>
              In just one day, our designers created a high-fidelity landing
              page with screenshots demonstrating how each feature worked.
            </Trans>
          </p>
          <br />
        </div>

        <div className="section__title">
          <PostIt className="sticky" />
        </div>
        <div className="section__content">
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="OSDE's website prototype"
          />
        </div>
      </div>
    </StyledSection>
  )
}

export default Prototyping
