import React from "react"
import styled, { css } from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

const Dot = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${colors.cloudy};
  border-radius: 50%;

  ${props =>
    props.red
      ? css`
          background-color: #e72534;
        `
      : ``}

  ${props =>
    props.orange
      ? css`
          background-color: #ffbf49;
        `
      : ``}

  ${props =>
    props.yellow
      ? css`
          background-color: #fcf113;
        `
      : ``}

  ${props =>
    props.green
      ? css`
          background-color: #94cd68;
        `
      : ``}

  ${props =>
    props.blue
      ? css`
          background-color: #4a90e2;
        `
      : ``}

  ${props =>
    props.purple
      ? css`
          background-color: #815f9d;
        `
      : ``}
`

const StyledUserTest = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.cloudy};

  &:last-of-type {
    border-bottom: 0;
  }

  ${breakpoint.medium`
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  `}

  .test__results {
    order: 0;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    ${breakpoint.medium`
      order: 1;
      margin-bottom: 0;
    `}

    h4 {
      order: 1;
      margin-left: 16px;
      font-weight: 700;
    }

    .dots {
      display: flex;

      ${Dot} {
        margin-right: 8px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .test__content {
    width: 100%;
    order: 1;
    font-family: ${fonts.userTest}, cursive;
    font-size: 16px;
    line-height: 1.2em;

    ${breakpoint.medium`
      max-width: 448px;
      width: calc(100% - 192px);
      order: 0;
    `}
  }
`

const UserTest = props => (
  <StyledUserTest>
    <div className="test__results">
      <h4>{props.result}</h4>
      <div className="dots">
        <Dot red={props.red} />
        <Dot orange={props.orange} />
        <Dot yellow={props.yellow} />
        <Dot green={props.green} />
        <Dot blue={props.blue} />
        <Dot purple={props.purple} />
      </div>
    </div>
    <p className="test__content">{props.text}</p>
  </StyledUserTest>
)

export default UserTest
