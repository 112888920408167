import React from "react"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Components
import Section from "components/case-study-section/"
import Container from "components/container/"

// Post Its
import InTwoYears from "assets/icons/case-studies/osde/in-two-years-time.inline.svg"
import PostItOne from "assets/icons/case-studies/osde/can-we-1.inline.svg"
import PostItTwo from "assets/icons/case-studies/osde/can-we-2.inline.svg"
import PostItThree from "assets/icons/case-studies/osde/can-we-3.inline.svg"

const StyledSection = styled(Section)`
  padding-top: 0 !important;
`

const StyledContainer = styled(Container)`
  max-width: 928px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 24px auto;
  overflow-x: auto;

  ${breakpoint.medium`
    flex-wrap: nowrap;
    margin: 40px auto;
    overflow: hidden;
  `}
`

const PostItWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  overflow-x: auto;

  &:first-of-type {
    ${breakpoint.medium`
      margin-right: 40px;
    `}
  }

  &:last-of-type {
    ${breakpoint.small`
      
      svg {
        width: calc((100% - 16px ) / 3);
      }
    `}
    ${breakpoint.medium`
      svg {
        width: 224px;
      }
    `}
  }

  ${breakpoint.small`
    width: 100%;
    justify-content: center;
  `}

  ${breakpoint.medium`
    width: auto;
  `}

  svg {
    flex-shrink: 0;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    ${breakpoint.small`
      flex-shrink: 1;
    `}

    ${breakpoint.medium`
      width: 224px;
      height: 143px;
    `}
  }
`

const WeekOne = () => (
  <StyledSection>
    <div className="container">
      <div className="section__title"></div>
      <div className="section__content">
        <p className="subtitle">
          <Trans>Week 1</Trans>
        </p>
        <p className="title">
          <Trans>Design Sprint</Trans>
        </p>
        <p className="paragraph--small">
          <b>
            <Trans>Day 1 and 2 - Workshops</Trans>
          </b>
        </p>
        <p>
          <Trans>
            Through the structured activities of Day 1 of the Design Sprint, we
            aligned on a common challenge. These are the Sprint Questions and
            2-year goal that were defined:{" "}
          </Trans>
        </p>
      </div>
    </div>

    <StyledContainer>
      <PostItWrapper>
        <InTwoYears />
      </PostItWrapper>

      <PostItWrapper>
        <PostItOne />
        <PostItTwo />
        <PostItThree />
      </PostItWrapper>
    </StyledContainer>

    <div className="container">
      <div className="section__title"></div>
      <div className="section__content">
        <p>
          <Trans>
            The second day of the Design Sprint workshop culminated in a
            storyboard of a landing page which explained in detail each of the 4
            features.{" "}
          </Trans>
        </p>
        <br />
        <ul className="bullets">
          <li>
            <Trans>Appointment scheduling</Trans>
          </li>
          <li>
            <Trans>Medical history</Trans>
          </li>
          <li>
            <Trans>Video appointments</Trans>
          </li>
          <li>
            <Trans>Digital prescriptions</Trans>
          </li>
        </ul>
        <br />
        <p>
          <Trans>
            We chose to focus on these 4 tools, because we knew that these were
            the places where we’d find the most resistance from doctors. The
            more challenging the solution we choose to tackle in the Design
            Sprint, the better the results.
          </Trans>
        </p>

        <br />

        <p>
          <Trans>
            It was equally important to focus on the visual elements and the
            copy so that the users could understand exactly what each of these
            new tools was all about.
          </Trans>
        </p>

        <br />

        <p>
          <Trans>
            After 2 long days of workshopping, we were ready to start designing
            the solution and recruiting users to test it out.
          </Trans>
        </p>
      </div>
    </div>
  </StyledSection>
)

export default WeekOne